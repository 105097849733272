@import "@vmf/components/src/assets/style/_index.scss";
















































.large-screen-box {
    > .inner {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: stretch;
        overflow: hidden;
        > .header {
            position: relative;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            background-image: linear-gradient(to right, #92271b, transparent);
            padding: $padding-small;
            border-bottom: 1px solid #92271b;

            > .icon {
                width: 27px;
                margin-right: $margin-small;
            }
            > .label {
                flex: 1 1 1%;
                font-weight: bold;
                color: #f0deaa;
                letter-spacing: 0.2em;
            }

            &:after {
                content: '';
                position: absolute;
                bottom: 2px;
                right: 2px;
                display: block;
                border-top: 4px solid transparent;
                border-left: 4px solid transparent;
                border-right: 4px solid #92271b;
                border-bottom: 4px solid #92271b;
            }
        }
        > .body {
            flex: 1 1 1%;
            padding: $padding-small;
        }
    }
}
