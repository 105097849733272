@import "@vmf/components/src/assets/style/_index.scss";






































































































.male-to-female-ratio {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    padding: $padding;
    > .echarts {
        flex: 1 1 1%;
        width: 100%;
        height: 100%;
    }
}
