@import "@vmf/components/src/assets/style/_index.scss";































































































































































































































.tooltip-wrap {
    .label {
        color: #f2d1a8;
        font-size: 16px;
    }
}
.large-screen-map {
    height: 100%;
    padding: $padding;
    background: transparent url('./images/decoration.png') center center no-repeat;
    background-size: contain;
    > .echarts {
        flex: 1 1 1%;
        width: 100%;
        height: 100%;
    }
}
