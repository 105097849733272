@import "@vmf/components/src/assets/style/_index.scss";
















































.module-statistics {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    > .item {
        flex: 0 0 (100% / 4);
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        > .icon {
            width: 66px;
            margin-right: $margin;
        }
        > .content {
            flex: 1 1 1%;
            > .value {
                font-size: 24px;
                font-weight: bold;
                color: #f8ca6c;
            }
            > .label {
                font-size: 14px;
            }
        }
    }
}
