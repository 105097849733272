@import "@vmf/components/src/assets/style/_index.scss";



















































































































.age-analysis {
    height: 100%;
    padding: $padding;
    > .echarts {
        width: 100%;
        height: 100%;
    }
}
