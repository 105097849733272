@import "@vmf/components/src/assets/style/_index.scss";











































































.party-member-study {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    padding: $padding-big 0;

    > .item {
        flex: 0 0 (100% / 3);
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: $padding 0;

        > .icon {
            width: 58px;
            margin-right: $margin;
        }
        > .content {
            flex: 1 1 1%;
            > .value {
                font-size: 20px;
                font-weight: bold;
                color: #f6efcc;
            }
            > .label {
                font-size: 12px;
            }
        }
    }
}
