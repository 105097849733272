@import "@vmf/components/src/assets/style/_index.scss";



















































.progress-bar {
    > .inner {
        position: relative;
        //background-color: #e4423f;
        border: 1px solid #e4423f;
        height: 20px;
        padding: 2px;
        > .bar {
            height: 100%;
            background-image: linear-gradient(to right, #54140c, #e4423f);
            mask-image: url('./images/mask2.png');
            mask-repeat: no-repeat;
            mask-size: 100% 100%;
        }

        > .label {
            position: absolute;
            top: 50%;
            right: $padding;
            transform: translateY(-50%);
            font-size: 14px;
            text-align: right;
        }
    }

    &.secondary {
        > .inner {
            border: 1px solid #e29735;
            > .bar {
                background-image: linear-gradient(to right, #725000, #e29735);
            }
        }
    }
}
