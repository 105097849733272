@import "@vmf/components/src/assets/style/_index.scss";


























































.party-reward-statistics {
    > .line {
        height: 1px;
        background-image: linear-gradient(to right, transparent, #fff, transparent);
    }
    > .item {
        padding: $padding;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        > .circle-progress {
            margin-right: $margin;
        }

        > .content {
            flex: 1 1 1%;

            > .label {
                margin-bottom: $margin-small;
            }

            > .sub-label {
                margin-top: $margin-small;
                font-size: 14px;
            }
        }
    }
}
