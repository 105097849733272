@import "@vmf/components/src/assets/style/_index.scss";































































































































.large-screen-page {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    background-color: #1f150f;
    color: #fff;

    img {
        width: 100%;
        height: auto;
        display: block;
    }

    > .inner {
        flex: 0 0 auto;
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: stretch;
        width: 1920px;
        height: 1080px;
        background-image: linear-gradient(to bottom, #180303 30%, #633b2d 70%, #633b2d 80%, #592a24 105%);
        color: #fff;
        font-size: 16px;

        &:after {
            position: absolute;
            right: 0;
            top: 50px;
            content: '';
            background: transparent url('./images/bg.png') center no-repeat;
            width: 521px;
            height: 412px;
        }

        > .header {
        }

        > .body {
            flex: 1 1 1%;
            position: relative;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: stretch;

            > .block {
                flex: 1 1 1%;
                padding: $padding;
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: stretch;
                //background-color: rgba(#f00, 0.2);

                &.left {
                }
                &.center {
                    flex: 2;
                }
                &.right {
                }
            }
        }
    }
}
